@use '../_config.scss' as *;

.section {
    padding-top: 60px;

    &__title {
        @include fontSize(heading, heading-1, false);
        margin-bottom: 0px;
    }

    &__title-search-product {
        @include fontSize(heading, heading-3, false);
        margin-bottom: 0px;
    }

    &__header-title {
        display: flex;
        align-items: center;
    }

    &__header {
        display: flex;
        align-items: center;
        color: color(black);
        padding-bottom: 30px;

        &--light {
            color: color(black, white);
        }
    }

    &__link {
        text-decoration: none;
        display: flex;
        align-items: center;
        color: color(black, dark-grey);
        @include transition(all 0.5s ease);
        @include fontSize(button, button, false);
        &:hover {
            color: color(red);
        }
    }
    
    &__link-light {
        color: color(black, white);
        @include transition(all 0.5s ease);

        &:hover {
            color: color(black, gray);
        }
    }

    &__link-icon {
        margin-left: 8px;
    }

    // Breadcrumb
    &__breadcrumb-container {
        margin-bottom: 15px;
    }

    &__breadcrumb-link {
        text-decoration: none;
        color: color(black, dark-grey);
        @include fontSize(button, button, false);

        &:hover {
            color: color(red)
        }
    }

    .breadcrumb {
        align-items: center;
    }

    .breadcrumb-item.active {
        color: color(red);
        @include fontSize(button, button, false)
    }

    @media (max-width: 991.98px) {
        padding-top: 25px;

        &__header {
            padding-bottom: 20px;
        }

        &__breadcrumb-container {
            margin-bottom: 8px;
        }
    }

    @media (max-width: 575.98px) {
        &__title {
            font-size: 16px;
            font-weight: 800;
            line-height: 22px;
        }

        &__link {
            font-size: 12px;
            line-height: 16px;
            font-weight: 600;
        }
    }
}