@use '../_config.scss' as *;

.login {
    .modal-header {
        border-bottom: none;
    }

    .input-group-text {
        background-color: color(black, white);
    }

    .btn:active {
        border-color: color(black, medium-grey);
        border-left-color: transparent;
    }

    .form-label {
        color: color(black);
        @include fontSize(body, body-1-medium, false);
    }

    .form-check {
        @include fontSize(body, body-1-medium, false);
    }

    .modal-lg {
        max-width: 650px;
    }

    &__modal-footer {
        text-align: center;
        margin-top: 15px;
        padding-top: 15px;
        border-top: 1px solid color(black, medium-grey);
    }

    &__modal-title {
        @include fontSize(heading, heading-1, false);
    }

    &__modal {
        padding-left: 60px;
        padding-right: 60px;
        padding-bottom: 60px;
    }

    &__pass-input {
        border-right: none;
    }

    &__pass-btn {
        border-top: 1px solid color(black, medium-grey);
        border-bottom: 1px solid color(black, medium-grey);
        border-right: 1px solid color(black, medium-grey);
        display: flex;
        padding: 10px 12px;

        &:hover {
            border-top: 1px solid color(black, medium-grey);
            border-bottom: 1px solid color(black, medium-grey);
            border-right: 1px solid color(black, medium-grey);
        }
    }

    &__input {
        border-color: color(black, medium-grey);
        padding: 10px 12px;

        &:focus {
            box-shadow: none;
            border-color: color(red);
        }
    }

    &__input:focus + &__pass-btn {
        border-top-color: color(red);
        border-right-color: color(red);
        border-bottom-color: color(red);
    }

    

    &__input-container {
        margin-bottom: 15px;
    }
    
    &__footer-text {
        @include fontSize(body, body-1, false);
        display: block;
        margin-top: 15px;
    }

    &__footer-link {
        color: color(red);
        @include fontSize(body, body-1-semibold, false);
        text-decoration: none;

        &:hover {
            color: color(red, dark-red);
        }
    }

    .form-check-input:checked[type=checkbox] {
        border-color: color(red);
        background-color: color(red);
        box-shadow: none;
    }

    .form-check-input:focus {
        box-shadow: none;
        border-color: color(red);
    }

    @media screen and (max-width: 575.98px) {
        .modal-content{
            padding-top: 15px !important;
        }
        .modal-header{
            padding-bottom: 15px !important;
        }

        &__modal {
            padding-left: 24px;
            padding-right: 24px;
        }    

        &__input-container{
            margin-bottom: 16px !important;
        }

        .form-label{
            margin-bottom: 4px !important;
        }

        &__input{
            padding: 10px 12px !important;
        }

        &__pass-btn{
            padding: 10px 12px !important;
        }

        .form-check{
            margin-bottom: 0;
        }

        &__modal-footer{
            margin-top: 8px;
            padding-top: 12px; 
        }
    }

}

.verify-email {
    // @include imageRatio(136, 94, false);

    &__img {
        width: 136px;
        height: 94px;
    }

    &__modal {
        text-align: center;
    }

    &__title {
        @include fontSize(heading, heading-1, false);
    }

    &__desc {
        @include fontSize(body, body-1, false);
        margin-bottom: 20px;
    }

    &__text {
        margin-top: 20px;
    }
}

