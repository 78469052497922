@use '../_config.scss' as *;

.products {
    @include imageRatio(342, 342, true, contain);

    &__swiper-card {
        max-width: 256.5px;
    }

    &__image-text {
        max-width: 342;
        max-height: 342;
    }

    &__brand {
        color: color(red);
        line-height: 1;
        margin-bottom: 0px;
        text-decoration: none;
        @include fontSize(body, body-2, false)
    }

    &__category {
        @include fontSize(body, body-1-semibold, false);
        color: color(black);
    }

    &__title {
        @include fontSize(body, body-1, false);
        color: color(black);

        &:before {
            content: "- ";
        }
    }

    &__link-container {
        display: block;
        text-decoration: none;
    }

    &__price {
        @include fontSize(card, disc-price, false);
        text-decoration: line-through;
        color: color(black, gray);
        padding-right: 8px;
    }

    &__discount-price {
        color: color(red);
        @include fontSize(card, title-1, false)
    }

    &__discount {
        @include fontSize(card, tag-1, false);
        display: flex;
        align-items: center;
    }

    &__fav {
        border: 1px solid color(black, medium-grey);
        padding-left: 14px;
        padding-right: 14px;
        border-radius: 10px;

        &:active {
            border: 1px solid color(black, medium-grey) !important;
            outline: none;
        }

        &:hover {
            border: 1px solid color(black, medium-grey);
            background-color: color(black, light-grey);
        }
    }

    &__shop-btn {
        margin-right: 10px;
    }

    &__ribbon {
        display: inline-block;
        margin-top: 13px;
        margin-right: 13px;
        position: absolute;
        top: 0px;
        right: 0px;

    }

    &__item {
        position: relative;
        border: transparent;
        height: 100%;
    }

    &__shadow {
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    }

    &__search-shadow {
        &:hover {
            box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
        }
    }

    &__image {
        position: relative;

        &:after {
            content: '';
            position: absolute;
            background-color: color(black);
            opacity: .5;
            width: 100%;
            height: 100%;
            border-top-left-radius: 5px;
            border-top-right-radius: 5px;
            top: 0px;
        }
    }

    &__text-highlight {
        position: relative;

        &:after {
            content: 'Discontinue';
            position: absolute;
            color: white;
            @include fontSize(body, body-1-bold, false);
            top: 50%;
            left: 50%;
            transform: translate(-50%);
            z-index: 1;
        }
    }

    &__shipping {
        padding-left: 5px;
    }

    &__shipping-label {
        width: 120px;
        height: 25px;

    }

    &__card-title {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        color: color(black);
        display: block;
        text-decoration: none;
    }

    &__swiper {
        padding-bottom: 10px;
    }

    &__discount-icon {
        max-width: 24px;
    }

    &__discount-text {
        margin-left: 4px;
        margin-bottom: 0px;
        color: color(black);
    }

    &__fav-icon {
        width: 18px;
    }

    &__list-swiper {
        padding-bottom: 10px;
    }

    &__category-item {
        height: unset !important;
    }

    .card-body {
        padding: 13px;
    }

    .card-text {
        margin-bottom: 0px;
        display: flex !important;
        flex-direction: row;
        align-items: end;
    }

    .card-footer {
        background-color: transparent;
        padding: 13px 13px;
        border-top: 1px solid color(black, light-grey);
    }

    .btn-primary{
        padding: 12px !important;
    }
    
    .btn-secondary{
        padding: 12px !important;
    }

    @media screen and (max-width: 485.98px) {
        &__brand {
            margin-bottom: 4px;
        }

        &__card-title {
            margin-bottom: 10px;
        }

        &__ribbon {
            margin-top:5px;
            margin-right: 8px;
        }

        &__shipping-label {
            width: 110px;
            height: 20px;
        }

        &__fav {
            padding-left: 13px;
            padding-right: 13px;
        }

        &__fav-icon {
            width: 15px;
        }

        &__shop-btn {
            display: inline-flex !important;
            align-items: center !important;
            justify-content: center !important;
        }

        .btn-secondary {
            font-size: 10px;
            padding: 13px !important;
        }

        .card-text {
            display: flex;
            flex-direction: column-reverse;
            align-items: start;
        }

        .btn-primary {
            font-size: 10px;
            padding: 13px !important;
        }

        .btn {
            --bs-btn-padding-x: none;
        }
    }

    @media screen and (max-width: 421.98px) {
        &__shipping-label {
            width: 100px;
            height: 20px;
        }

        &__discount {
            margin-top: 8px;
            align-items: flex-start;
        }

        &__shop-btn {
            display: inline-flex !important;
            align-items: center !important;
            justify-content: center !important;
        }

        &__fav {
            padding-left: 10px;
            padding-right: 10px;
        }

        .btn-secondary {
            font-size: 9px;
            padding: 12px !important;
        }
        
        .btn-primary {
            font-size: 9px;
            padding: 12px !important;
        }

        .card-text {
            flex-direction: column-reverse;
            align-items: start;
        }

        .btn {
            --bs-btn-padding-x: none;
        }
    }

    @media screen and (max-width: 389.98px) {
        .btn-secondary {
            font-size: 12px;
            padding-top: 17px !important;
            padding-bottom: 17px !important;
        }
        
        .btn-primary {
            font-size: 12px;
            padding-top: 17px !important;
            padding-bottom: 17px !important;
        }

        &__fav {
            padding-left: 15px;
            padding-right: 15px;
        }

        &__discount {
            align-items: center;

        }

        &__fav-icon {
            width: 22px;
        }

        &__shipping-label {
            width: 140px;
            height: 50px;
        }

        &__brand{
            font-size: 14px !important;
        }

        &__category{
            font-size: 18px !important;
        }

        &__title{
            font-size: 16px !important;
        }

        &__price{
            font-size: 12px !important;
        }

        &__discount-price{
            font-size: 16px !important;
        }

        &__discount{
            font-size: 12px !important;
        }

        .btn {
            --bs-btn-padding-x: 0.75rem;
        }
    }
}

.tooltip-inner {
    text-align: left;
    @include fontSize(card, tag-1);
    max-width: 220px !important;
}