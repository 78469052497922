@use '../_config.scss' as *;
@use './btn';

@keyframes fadeIn {
    from {
        opacity:0;
    }

    to {
        opacity:1;
    };
}

.cart {
    // @include imageRatio(80, 80, false, contain);
    .offcanvas {
        width: 550px;
        padding: 30px;

        @media (max-width: 992px) {
            width: 475px;
        }
    }

    .offcanvas-header {
        padding: 0px 0px 30px 0px;
    }

    .offcanvas-body {
        padding: 0px;
    }

    .offcanvas-title {
        @include fontSize(heading, heading-2, false);
    }

    &__btn-close{
        &:focus{
            box-shadow: none !important;
        }
    }

    &__container {
        margin-top: 30px;
    }

    &__header {
        @include fontSize(heading, heading-3, false);

        @media (max-width: 991.98px) {
            font-weight: 700;
        }
    }

    &__product {
        display: flex;
        margin-bottom: 15px;
        padding-bottom: 12px;
        margin-right: 10px;
    }

    &__product-border {
        border-bottom: 1px solid color(black, light-grey);
    }

    &__img {
        width: 80px;
        height: 80px;
    }

    &__right {
        width: 85%;
        padding-left: 10px;
    }
    
    &__price-container {
        text-align: right;

        @media (max-width: 576px) {
            display: flex;
            justify-content: space-between;
        }
    }

    &__brand {
        @include fontSize(body, body-2, false);
        color: color(red);
        margin-bottom: 4px;
        text-decoration: none;
    }

    &__title {
        display: block;
        text-decoration: none;
        color: color(black);
        @include fontSize(body, body-1-bold, false);
    }

    &__title-light {
        @include fontSize(body, body-1, false);
    }

    &__price {
        @include fontSize(body, body-1-bold, false);
    }

    &__qty {
        font-size: 14px;
        color: color(black, gray);
        margin-bottom: 4px;
    }

    &__btn-right {
        padding-left: 10px;
    }

    &__btn-left {
        padding-right: 10px;
    }

    &__total {
        display: flex;
    }

    &__total-item {
        @include fontSize(body, body-1, false);
    }

    &__total-price {
        @include fontSize(heading, heading-3, false);
        color: color(red);
    }

    &__footer {
        padding-top: 25px;
        border-top: 1px solid color(black, light-grey);
    }

    &__success {
        background-color: color(red);
        padding: 15px;
        margin-bottom: 15px;
        border-radius: 0px;
        opacity: 0;
        display: block;
        transition: opacity 1s ease;
    }

    &__notif-text {
        color: color(black, white);
        margin-left: 10px;
        font-size: 23px;
    }

    &__success-icon {
        width: 26px;
        height: 26px;
    }

    &__alert-body {
        display: flex;
        align-items: center;
    }

    &__btn-close:focus {
        box-shadow: none;
    }

    &__success-show {
        opacity: 1;
    }

    &__success-hide {
        display: none;
    }
    

    .toast-text {
        color: color(black, white);
        @include fontSize(heading, heading-3, false);
    }
}