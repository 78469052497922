// |‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾| //
// | tmdrExtend v.1.0.1                      | //
// | last update : 28 / 06 / 2023            | //
// |_________________________________________| //

%ratioItem {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}