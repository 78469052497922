@use '../_config.scss' as *;

.pagination-container {
    padding-top: 60px;

    .page-item {
        margin-left: 4px;
        margin-right: 4px;
        width: 40px;
        height: 40px;
        text-align: center;
        border-radius: 2px;
    }

    .active {
        background-color: color(red);
        color: color(black, white) !important;
        border-color: color(red);
    }

    .page-link {
        color: color(red);
        border-radius: 2px;
        border-color: color(black, light-grey);
    }

    .page-link:focus {
        box-shadow: none;
        background-color: color(black, white);
        color: color(black, red);
        border-color: color(black, light-grey);
    }

    .page-item:last-child .page-link {
        border-top-right-radius: 2px;
        border-bottom-right-radius: 2px;
    }

    .page-item:first-child .page-link {
        border-top-left-radius: 2px;
        border-bottom-left-radius: 2px;
    }
}