@use '../_config.scss' as *;
@use './_cart.scss';
@use './_search.scss';
@use './_login.scss';

.top-header {
    background-color: color(red);

    @media (max-width: 991.98px) {
        display: none;
    }

    &__container {
        display: flex;
        justify-content: space-between;
    }

    &__menu-list {
        list-style: none;
        margin: 0px;
        padding-left: 0px;
        display: flex;
        align-items: center;
    }

    &__menu-right {
        margin-left: 10px;
        padding: 8px 8px;

        @media (max-width: 991px) {
            padding: 12px 8px;
        }

        @media (max-width: 1399px) {
            margin-left: 0px;

        }

        @media (max-width: 1199px) {
            margin-left: 0px;

        }
    }

    &__menu-left {
        margin-right: 10px;
        padding: 8px;

        @media (max-width: 1399px) {
            margin-right: 0px;

        }

        @media (max-width: 1199px) {
            margin-right: 0px;
        }
    }

    &__menu-right a {
        @media (max-width: 991px) {
            font-size: 14px;
            font-weight: 600;
        }
    }

    &__menu-left a {
        @media (max-width: 991px) {
            font-size: 12px;
            font-weight: 400;
        }
    }

    &__link-hover {
        &:hover {
            color: color(black, grey);
            fill: color(black, grey);
        }
    }

    &__link {
        text-decoration: none;
        color: #FFFFFF;
        font-size: 13px;
        fill: color(black, white);
        display: flex;
        align-items: center;
        line-height: 1;
        @include transition(all .5s ease);

        @media (max-width:1199.98px) {
            font-size: 12px;
        }

        @media (max-width: 991px) {
            color: color(black);
            fill: color(black);
        }
    }

    &__language>&__link {
        @media (max-width: 991px) {
            fill: color(black, white);
            color: #FFFFFF;
        }
    }

    &__language {
        display: inline-block;

        @media (max-width: 991px) {
            display: none;
        }
    }

    &__whatsapp>&__link {
        @media (max-width: 991px) {
            fill: color(black, white);
            color: #FFFFFF;
        }
    }

    &__dropdown {
        z-index: 9999;
        text-align: center;
        margin-top: 10px;
    }

    .dropdown-menu {
        min-width: 8rem;
        border-color: transparent;
        box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.10);
    }

    .dropdown-item {
        &:hover {
            background-color: color(black, white);
            color: color(red);
        }
    }

    .dropdown-menu.show {
        inset: 0px 0px auto 50% !important;
        transform: translate(-50%, 26px) !important;
        margin-top: 10px !important;
    }

    &__text {
        padding-left: 8px;
    }

    &__offcanvas-user {
        list-style: none;
        padding-left: 0px;
        display: none;

        @media (max-width: 991.98px) {
            display: block;
            margin-bottom: 20px;
        }
    }

    &__offcanvas-left {
        display: none;

        @media (max-width: 991.98px) {
            display: block;
        }
    }



    &__offcanvas-right {
        display: none;

        @media (max-width: 991.98px) {
            display: block;
        }
    }

    &__offcanvas-register {
        display: none;

        @media (max-width: 991.98px) {
            display: block;
            padding-left: 0px;
        }
    }

    &__offcanvas-language {
        display: none;

        @media (max-width: 991.98px) {
            list-style: none;
            display: flex;
            justify-content: center;
            margin-bottom: 0px;
            padding-top: 12px;
            padding-left: 0px;
        }
    }

    &__offcanvas-operational {
        display: none;

        @media (max-width: 991.98px) {
            display: block;
        }
    }

    &__icon {
        width: 16px;
        height: 16px;
    }
}

.bottom-header {
    // @include imageRatio(218, 67, false, contain);
    background-color: color(black, white);

    &__shadow {
        box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.09);
    }

    &__container {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        margin-right: 30px;

        @media (max-width: 1199px) {
            margin-right: 15px;
        }
    }

    &__logo-img {
        @media (max-width: 1399px) {
            max-width: 200px;
        }

        @media (max-width: 1199px) {
            max-width: 170px;
        }

        @media (max-width: 425px) {
            max-width: 120px;
        }
    }

    &__left {
        display: flex;
        align-items: center;
    }

    &__link {
        text-decoration: none;
        color: #0A0A0A;
        display: flex;
        align-items: center;
        padding: 8px;
        @include fontSize(body, body-1-semibold, false);

        &--highlight {
            color: color(blue);
            font-weight: 700;
        }

        @media (max-width: 1199.98px) {
            font-size: 14px;
        }
    }

    &__img {
        max-width: 26px;
        max-height: 26px;
    }


    &__text-cart {
        @media (max-width: 991px) {
            display: none;
        }
    }

    &__left-link {
        border-radius: 8px;
        @include transition(all 0.5s ease);
        padding: 10px 10px;

        &:hover {
            background-color: color(black, light-grey);
        }

        @media (max-width: 991px) {
            padding: 12px 8px;

            &:hover {
                background-color: transparent;
            }
        }
    }

    &__right-menu {
        list-style: none;
        margin-bottom: 0px;
        padding-left: 0px;
        display: flex;
        align-items: center;
    }

    &__menu-list {
        
        border-radius: 8px;
        cursor: pointer;
        @include transition(all 0.5s ease);

        &:hover {
            background-color: color(black, light-grey);
        }
    }

    &__nav-left {
        align-items: center;
        position: relative;

        @media (max-width: 991px) {
            align-items: start;
        }
    }

    &__cart {
        position: relative;
        margin-right: 16px;

        &:after {
            display: block;
            content: "";
            position: absolute;
            right: -8px;
            top: 0px;
            border-right: 1px solid lightgray;
            height: 100%;
        }

        @media (max-width: 991px) {
            margin-right: 0px;

            &:after {
                display: none;
            }
        }
    }

    &__text {
        padding-left: 5px;
    }

    &__outside {
        margin-right: 8px;
        margin-left: auto;
    }

    .navbar-toggler {
        padding: 0px 5px;
        border: none;

        &:focus {
            box-shadow: none;
        }

    }

    .navbar-toggler-icon {
        width: 25px;
        height: 25px;
    }

    &__search-menu-right {
        display: flex;
        justify-content: space-between;
    }

    &__close-btn {
        border: none;
        border-radius: 8px;
        display: flex;
        align-items: center;
        padding: 8px 16px;
        background-color: transparent;
        @include transition(all .5s ease);

        @media (max-width: 991.98px) {
            padding: 3px 5px;
        }

        &:hover {
            background-color: color(black, light-grey) !important;
        }

        &:active{
            border: transparent;
            box-shadow: none;
        }
    }

    &__btn-text {
        @include fontSize(body, body-1, false);
        display: block;
        color: color(black);

        @media (max-width: 991.98px) {
            display: none;
        }
    }

    &__search-icon {
        background-color: color(black, white);
        border: 1px solid color(black, medium-grey);
        border-right: none;
        padding-right: 0px;
        border-radius: 8px 0px 0px 8px;
        display: flex;
        align-items: center;
        padding-left: 12px;
    }

    &__search-input-container {
        border-left: none;
        // border-radius: 0px 8px 8px 0px;
        width: 85%;
        border-color: color(black, medium-grey);;
        line-height: 24px;
        @include fontSize(body, body-1, false);

        @media (max-width: 991.98px) {
            width: 92%;
        }

        @media (max-width: 767.98px) {
            width: 90%;
        }

        @media (max-width: 425px) {
            width: 88%;
        }

        @media (max-width: 358px) {
            width: 85%;
        }
    }

    &__search-input {
        border-left: none;
        border-radius: 0px 8px 8px 0px;
        border-color: color(black, medium-grey);
        padding: 10px 8px;

        &::placeholder {
            color: color(black, gray);
        }

        &::-ms-input-placeholder {
            color: color(black, gray);
        }

        &:-ms-input-placeholder {
            color: color(black, gray);
        }

        &:focus {
            border-color: color(red);
            box-shadow: none;
        }
    }

    &__search-icon:has(+ &__search-input:focus) {
        border-color: color(red);
    }

    &__register {
        @media (max-width: 991px) {
            display: none;
        }
    }
}

.header {
    &__menu-list {
        margin-right: 18px;

        @media (min-width: 992px) {
            margin-right: 4px;
        }

        @media (max-width: 991px) {
            width: 100%;
            border-bottom: 1px solid color(black, light-grey);
        }

        
    }

    &__register {
        display: block;
        width: 100%;
        text-align: center;
        border-radius: 8px;
        margin: 15px auto;
        text-decoration: none;
    }

    &__language {
        padding-right: 15px;
        padding-left: 15px;

        @media (max-width: 991px) {
            color: red;
        }
    }

    .disable {
        color: color(black, gray);
        pointer-events: none;
    }

    &__language-right {
        border-right: 1px solid color(black, medium-grey);
    }

    &__user-header {
        background-color: transparent;
        box-shadow: none;
    }

    &__user-btn {
        padding: 12px 8px;
        font-size: 14px;
        text-decoration: none;
    }

    &__user-text {
        padding-left: 5px;
    }

    &__user-menu {
        display: block;
        font-size: 14px;
        text-decoration: none;
        color: color(black);
        padding-top: 12px;
        padding-bottom: 12px;
    }

    &__user-list {
        padding: 0px 0px 0px 32px;
    }

    &__user-btn:not(.collapsed) {
        background-color: transparent;
        box-shadow: none;
    }

    &__user-container {
        border: none;
    }

    &__tag {
        color: color(black, gray);
        font-size: 12px;
        padding: 8px;
    }
}