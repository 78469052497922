@use '../_config.scss' as *;

.toast {
    position: fixed;
    top: 90px;
    left: 50%;
    transform: translateX(-50%);
    background-color: color(blue, dark-blue);
    color: color(black, white);
    z-index: 9999;
    border-radius: 8px;
    
    .toast-body {
        padding: 8px;
        display: flex;
        align-items: center;
    }

    &__text {
        padding-left: 8px;
    }

    &__close-btn{
        color: color(black, white);
        &:hover{
            color: color(black, light-grey);
        }
        &:active{
            border-color: transparent !important;
        }
    }
}
