@use '../_config.scss' as *;

.side-content {
    &__container {
        padding-bottom: 30px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid color(black, medium-grey);
        padding-bottom: 20px;
    }


    &__title {
        @include fontSize(heading, heading-3, false);
        color: color(black);
    }

    &__view-more, &__search-view-more {
        text-decoration: none;
        @include fontSize(button, button, false);
        color: color(black, dark-grey);
        display: inline-block;
        padding-top: 20px;

        &:hover {
            color: color(red);
            fill: color(red);
        }
    }
    

    &__view-more-text {
        margin-right: 8px;
    }

    &__currency {
        background-color: color(black, white);
        border-right: none;
        padding-right: 4px;
        border-radius: 8px 0px 0px 8px;
        @include fontSize(body, body-1-bold, false);
    }

    &__price-input {
        border-left: none;
        border-radius: 0px 8px 8px 0px;
        border-color: color(black, medium-grey);
        @include fontSize(body, body-1, false);
    }

    &__checkbox {
        display: flex;
        justify-content: space-between;
        accent-color: color(red);
        width: 19px;
        height: 19px;
    }

    .form-check {
        @include fontSize(body, body-1, false);
        display: flex;
        align-items: center;
        margin-bottom: 10px;
        padding-left: 0px;
    }

    .form-check-input:checked[type=radio],
    .form-check-input:checked[type=checkbox] {
        border-color: color(red);
        background-color: color(red);
        box-shadow: none;
    }

    .input-group {
        margin-top: 10px;
    }

    .form-check-label {
        margin-left: 8px;
        color: color(black, dark-grey)
    }

    .form-check-input {
        width: 20px;
        height: 20px;
        margin-top: 0px;
        border-color: color(black, medium-grey);
    }

    .form-check-input:focus,
    .form-control:focus {
        box-shadow: none;
        border-color: color(black, medium-grey);
    }

    // Arrow in input type=number
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

    //Firefox
    input[type=number] {
        appearance: textfield;
        -moz-appearance: textfield;
    }

    .accordion-button:focus {
        box-shadow: none;
    }

    .accordion-button:not(.collapsed) {
        background-color: transparent;
        box-shadow: none;
    }

    .accordion-button {
        background-color: transparent !important;
        padding: 0px 0px 20px 0px;
        color: color(black);
        border-bottom: 1px solid color(black, medium-grey);
        margin-bottom: 10px;
    }

    .accordion-item {
        background-color: transparent;
        border: none;
    }

    .accordion {
        --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    }
}