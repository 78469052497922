@use '../_config.scss' as *;

.footer {
    background-color: color(blue, dark-blue);
    margin-top: 120px;

    &__logo {
        @media (max-width: 992px) {
            max-width: 280px;
        }
    }

    &__title-list {
        @include fontSize(heading, heading-2-semibold, false);
        color: color(black, white);
        margin-bottom: 24px;
    }

    &__list {
        list-style: none;
        padding-left: 0px;
        margin-left: 0px;
        
        @media (max-width: 576px) {
            justify-content: center;
        }
    }

    &__link {
        text-decoration: none;
        color: color(black, white);
        font-size: 16px;
        font-weight: 300;
        @include transition(all .5s ease);

        &:hover {
            color: color(red);
        }
    }

    &__copyright {
        text-align: center;
        color: color(black, white);
        padding-bottom: 20px;
        padding-top: 20px;
        border-top: 1px solid rgba(255, 255, 255, 0.3);
    }

    &__subscribe {
        color: color(black, white);
        background-color: color(red);
        position: relative;
        top: -60px;
        padding: 40px 60px;
        border-radius: 8px;
        
        @media (max-width: 576px) {
            border-radius: 8px;
            padding: 40px 0px;
        }

        @media (max-width: 575.98px) {
            border-radius: 0px;
        }
    }

    &__subscribe-top {
        @include fontSize(heading, heading-2, false);
        margin-bottom: 0px;
        text-align: left;

        @media (max-width: 991.98px) {
            text-align: center;
        }
    }

    &__subscribe-bottom {
        @include fontSize(body, body-1, false);
        margin-bottom: 0px;
        text-align: left;

        @media (max-width: 991.98px) {
            text-align: center;
        }
    }

    &__subscribe-btn {
        width: 100%;
        text-align: right;
    }

    &__copyright-top {
        margin-bottom: 0px;
        font-size: 14px;
        font-weight: 300;
        color: color(black, white);
        text-decoration: none;
    }

    &__copyright-bottom {
        margin-bottom: 0px;
        font-size: 12px;
        font-weight: 300;

        &--red {
            color: color(red);
            text-decoration: none;
        }
    }

    &__content {
        padding-bottom: 60px;

        @media (max-width: 576px) {
            text-align: center;
        }
    }

    &__content-list {
        margin-bottom: 14px;
    }

    &__sosmed {
        margin-right: 8px;
        text-align: center;
        border: 1px solid white;
        padding: 10px;
        border-radius: 50px;
        width: 50px;
        height: 50px;
        display: inline-block;
        font-size: 24px;
        color: white;
        @include transition(all .5s ease);

        &:hover {
            border-color: color(red);
            color: color(red);
        }

        @media (max-width: 1199px) {
            width: 40px;
            height: 40px;
            padding: 6px;
        }

        @media (max-width: 575.98px) {
            margin-right: 0px;
        }
    }

    &__policy {
        &:hover {
            color: color(red, dark-red);
        }
    }
}

.subscribe {
    text-align: left;
    &__title {
        @include fontSize(heading, heading-1, false);
    }

    &__label {
        display: block;
        margin-bottom: 4px;
        @include fontSize(body, body-1-medium, false);
    }

    &__input {
        width: 100%;
        padding: 10px 12px;
        color: color(black, gray);
        border: 1px solid color(black, medium-grey);
        border-radius: 8px;
        @include fontSize (body, body-1, false);
        margin-bottom: 15px ;

        &:disabled {
            background-color: color(black, medium-grey);
            border: 1px solid color(black, gray);
        }

        &::placeholder {
            color: color(black, gray);
            @include fontSize (body, body-1, false);
        }

        &:focus {
            border: 1px solid color(red, default);
            color: color(black, default);
            outline: none;
        }
    }

    &__success-img {
        width: 140px;
        height: 140px;
    }

    &__success-modal {
        text-align: center;
    }

    &__success-text {
        margin-top: 20px;
    }

    &__success-title {
        @include fontSize(heading, heading-1, false);
    }

    &__success-desc {
        @include fontSize(body, body-1, false);
    }

    &__modal-body {
        padding: 0px 60px 60px 60px;
    }

    &__modal-button {
        display: flex;
        width: 30%;
    }

    &__success-dialog {
        max-width: 490px;
    }

    &__dialog {
        max-width: 585px;
    }

    &__higlight {
        color: color(red);
    }

    .btn-close {
        padding-top: 20px;
        padding-right: 20px;
    }

    .modal-header {
        border-bottom: none;
    }

    @media (max-width: 575.98px) {
        &__modal-button {
            width: 100%;
        }

        &__modal-body {
            padding: 0px 24px 60px 24px;
        }
    }
}