@use '../_config.scss' as *;
@use '../components/toast';

.btn-primary {
    background-color: color(red);
    border-color: color(red);
    color: color(black, white);
    width: 100%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 12px;
    border-radius: 8px;
    @include transition(all .5s ease);
    @include fontSize(button, button, false);

    &:hover{
        background-color: color(red, dark-red);
        border-color: color(red, dark-red);
        color: color(black, white);

    }

    &:active {
        background-color: color(red, dark-red) !important;
        border-color: color(red, dark-red) !important;
        color: color(black, white) !important;
    }
}

.btn-secondary {
    background-color: color(black, gray);
    border-color: color(black, gray);
    width: 100%;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    padding: 12px;
    color: color(black, white);
    @include transition(all .5s ease);
    @include fontSize(button, button, false);

    &:hover {
        background-color: color(black, gray);
        border-color: color(black, gray);
    }
}

.btn-light {
    background-color: #FFFFFF;
    border-color: #FFFFFF;
    color: #CC0000;
    padding: 12px;
    width: 100%;
    transition: all 0.5s ease;
    font-size: 18px;
    font-weight: 600;
    display: inline-block;
    text-align: center;
    border-radius: 8px;
    text-decoration: none;
    @include transition(all .5s ease);
    @include fontSize(button, button, false);

    &:hover {
        background-color: color(black, medium-grey);
        border-color: color(black, medium-grey);
        color: color(red);
    }
}

.btn-outline-primary {
    background-color: color(black, white);
    border: 1px solid color(red);
    color: color(red);
    width: 100%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    // padding: 7px;
    padding: 12px;
    border-radius: 8px;
    @include transition(all .5s ease);
    @include fontSize(button, button, false);

    &:hover {
        background-color: color(black, light-grey);
        color: color(red, default);
        border: 1px solid color(red, default);
    }

    &:active {
        background-color: color(black, gray) !important;
        color: color(red, default) !important;
        border: 1px solid color(red, default) !important;
    }
}
.btn-outline-secondary {
    background-color: #F8F8F8;
    border: 1px solid color(red);
    color: color(red);
    width: 100%;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    padding: 12px;
    border-radius: 8px;
    @include transition(all .5s ease);
    @include fontSize(button, button, false);

    &:hover {
        background-color: color(black, light-grey);
        border-color: color(red, dark-red);
        color: color(red);
    }

    &:focus {
        background-color: color(black, white);
        border-color: color(red, dark-red);
        color: color(red);
    }
}

@media (max-width: 991.98px) {
    .btn-primary {
        padding-top: 17px;
        padding-bottom: 17px;
    }

    .btn-secondary {
        padding-top: 17px;
        padding-bottom: 17px;
    }

    .btn-light {
        padding-top: 17px;
        padding-bottom: 17px;
    }

    .btn-outline-primary {
        padding-top: 17px;
        padding-bottom: 17px;
    }

    .btn-outline-secondary {
        padding-top: 17px;
        padding-bottom: 17px;
    }
}