@use '../_config.scss' as *;

.modal {
    @include imageRatio(140, 140, false, contain);

    &__image {
        width: 140px;
        height: 140px;
        margin: 0 auto;
    }

    &__button {
        display: flex;
    }

    .modal-body {
        padding: 60px;
    }

    &__title {
        @include fontSize(heading, heading-1, false);
        margin-bottom: 8px;
        margin-top: 20px;
    }

    &__desc {
        @include fontSize(body, body-1-semibold, false);
        margin-bottom: 20px;
    }

    &__button-no {
        margin-right: 15px;
        &:hover{
            cursor: pointer;
        }
    }

    &__button-remove {
        width: 50%;
    }

    &__confirm-modal {
        text-align: center;
    }
    

    .btn-close{
        &:focus{
            box-shadow: none;
            border: none;
        }
    }

    @media (max-width: 576px) {

        @include imageRatio(100, 100, false, contain);

        &__image {
            width: 100px;
            height: 100px;
        }

        &__title {
            margin-top: 24px;
        }
    
        &__desc {
            margin-bottom: 24px;
        }
    

        .modal-body {
            padding: 24px;
        }
    }
}