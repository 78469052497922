@use 'sass:map';
@use 'sass:math';
@use '_config.scss' as *;
// @use 'plugin/bootstrap/bootstrap.scss' with (
//     $container-max-widths: (
//         sm: 540px,
//         md: 720px,
//         lg: 960px,
//         xl: 1140px,
//         xxl: 90%
//     ),
// );
@use 'plugin/_tmdrPreset.scss' with (
    $spacingIncrement: 15,
);

@use '../../node_modules/bootstrap/scss/bootstrap.scss';
@use './components/header';
@use './components/footer';
@use './components/side-content';
@use './components/product';
@use "../../node_modules/swiper/swiper.scss";
@use './components/modal';
@use './components/toast';
@use './components/btn';

// |‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾| //
// | Timedoor New Standard                   | //
// |_________________________________________| //

.ratio-item {
    @extend %ratioItem;
}

body {
    font-family: 'Nunito', sans-serif;
    background-color: #F8F8F8;
}