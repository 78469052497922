@use '../_config.scss' as *;
@use '../components/_section.scss';
@use '../components/_pagination.scss';

.search {
    .offcanvas-body {
        display: block;
        padding: 0px;
    }

    .offcanvas-top.h-auto {
        bottom: initial;
    }

    .nav-pills .nav-link.active {
        background-color: transparent;
        color: color(black);
    }

    &__title-search-product {
        @include fontSize(heading, heading-3, false);
        margin-bottom: 0px;
    }

    &__header-title {
        display: flex;
        align-items: center;
    }

    &__header {
        box-shadow: none;
        display: flex;
        align-items: center;
        color: color(black);
        padding-bottom: 30px;
    }

    &__filter {
        text-decoration: none;
        border: 1px solid color(black, medium-grey);
        width: 100%;
        text-align: center;
        background-color: white;
        border-radius: 8px;
        padding: 8px;
        color: color(black, dark-grey);
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 24px;
        @include fontSize(body, body-1, false)
    }

    &__filter-text {
        margin-left: 10px;
    }

    &__filter-button {
        margin-bottom: 20px;
    }

    &__top-menu {
        display: flex;
        flex-direction: row;
        width: 100%;

        @media (max-width: 991.98px) {
            flex-direction: column;
        }
    }

    &__close-btn {
        &:active {
            box-shadow: none;
            border: transparent;
        }
        &:focus {
            box-shadow: none;
            border: transparent;
        }
    }

    @media (max-width: 991.98px) {
        &__header {
            padding-bottom: 20px;
        }
    }

    @media (max-width: 389.98px) {
        &__product-list-card {
            width: 95%;
            margin-left: auto;
            margin-right: auto;
            padding: 5px;
        }
    }
}

.search-swiper {
    &__swiper-container {
        position: relative;
    }

    &__category-item {
        height: unset !important;
    }

    &__nav-btn {
        position: absolute;
        background-color: color(black, white);
        padding: 5px 12px;
        color: color(black);
        border-radius: 8px;
        bottom: 50%;
        fill: color(black, dark-grey);

        &:hover {
            background-color: color(black, light-grey);
        }

        @media (max-width: 991.98px) {
            display: none;
        }

        @media (max-width: 1046.98px) {
            bottom: -65px;
        }
    }

    &__prev-btn {
        left: -35px;

        @media (max-width: 1046.98px) {
            left: 38%;
        }

    }

    &__next-btn {
        right: -35px;

        @media (max-width: 1046.98px) {
            right: 38%;
        }
    }

    &__title {
        padding-left: 8px;
    }

    &__swiper {
        padding-bottom: 10px !important;
    }

    .swiper-button-disabled {
        opacity: 0.35;
    }
}

.search-filter-offcanvas {
    .offcanvas-title {
        @include fontSize(mobile, heading-1, true);
        line-height: 24px;
    }
}